<template>
  <!-- Text Input -->
  <div
    :id="name"
    :ref="(el) => (refs[name].value = el as HTMLInputElement)"
    class="relative flex w-full flex-col items-start space-y-px"
  >
    <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
    <label
      class="text-body-2 relative flex w-full flex-row items-center justify-between rounded-xl py-3"
      :class="[
        disabled || readOnly ? 'cursor-default' : 'cursor-pointer',
        compact ? 'h-10' : 'h-12',
        ghost ? 'bg-transparent p-0' : 'gap-2 bg-white px-4',
        slots.default && modelValue.length ? '!pl-2' : '!p-4',
      ]"
      @click="setInputFocused(true)"
    >
      <div
        class="absolute left-0 size-full rounded-xl outline-none transition-colors duration-200"
        :class="[
          { 'border-black-20 bg-black-05 ': disabled },
          error
            ? 'border-error-100 peer-hover/icon-prefix:border-error-100 peer-hover/icon:border-error-100 peer-hover/prefix:border-error-100 peer-hover/suffix:border-error-100 peer-hover:border-error-100 '
            : 'border-black-20 hover:border-primary-50 active:border-primary-100 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50  peer-focus:border-primary-100 peer-active:border-primary-100 peer-enabled:placeholder:text-black-100',
          isInputFocused ? '!border-primary-100' : '',
          ghost ? 'border-transparent' : 'border-[1.5px] border-solid',
        ]"
      ></div>
      <UiIcon
        v-if="showIcon"
        :name="icon"
        :class="[error ? 'text-black-60' : isInputFocused ? 'text-primary-100' : 'text-black-60']"
        class="peer/icon-prefix"
      />
      <div v-if="slots.default && modelValue.length" class="z-[1]">
        <slot />
      </div>
      <div v-else-if="multiple && modelValue.length" class="peer relative truncate hover:z-10 peer-hover:z-10">
        <div v-if="modelValue.length < 3 && !persistentPlaceholder">
          {{ multipleItemsEditableContent }}
        </div>
        <div
          v-else-if="modelValue.length > 0"
          class="text-caption flex h-6 cursor-pointer flex-row items-center gap-[2px] rounded-lg bg-primary-100 px-[6px] py-[3px] text-white"
          @click.stop="emits('update:modelValue', [])"
        >
          {{ modelValue.length }}
          <UiIcon name="small-close" size="xxs"></UiIcon>
        </div>
      </div>
      <input
        :id="name"
        ref="input"
        :value="multiple ? '' : simpleInputText"
        :text="multiple ? '' : simpleInputText"
        :name="name"
        type="text"
        :readonly="multiple"
        autocomplete="off"
        :placeholder="dynamicPlaceholder"
        :disabled="disabled || readOnly"
        class="peer h-[20px] w-full flex-1 truncate border-none bg-transparent outline-none placeholder:text-sm placeholder:font-normal placeholder:leading-5 disabled:z-10 disabled:text-black-60"
        :class="[
          {
            'placeholder:text-black-40': error,
            'placeholder:text-black-100': multipleItemsEditableContent,
            'text-body-2': compact,
          },
          textClass,
        ]"
        @focus="setInputFocused(true)"
        @keydown.backspace="eraseWord"
        @input="handleInput($event)"
        @keydown.enter="keyDownEnterInput"
      />
      <div class="peer relative flex flex-row items-center justify-end gap-1 hover:z-10 peer-hover:z-10">
        <transition name="fade">
          <div v-if="showSingleClearIcon" class="min-w-[16px]" @click.stop="handleClear">
            <UiIcon name="big-close-circle-filled" size="xxs" class="cursor-pointer text-black-60" />
          </div>
        </transition>
        <UiIcon
          v-if="!hideArrow"
          name="chevron-big-filled-down"
          :class="[
            error ? 'text-black-60' : 'text-black-60 peer-focus-visible:text-primary-100',
            { 'rotate-180 text-primary-100': isInputFocused },
          ]"
          class="peer/icon transition-all duration-200"
        />
      </div>
      <Teleport to="body">
        <transition name="fade">
          <div
            v-if="isInputFocused"
            :id="`${name}_select`"
            :ref="(el) => (refs[`${name}_select`].value = el as HTMLInputElement)"
            class="fixed z-[60] min-w-[200px] rounded-xl border-[1.5px] border-solid border-primary-10 bg-white p-2 shadow"
            :class="menuClasses"
          >
            <label
              v-if="multiple && !hideMultipleSearch"
              class="relative flex w-full min-w-[100px] flex-row items-center justify-between gap-2 px-4 py-3"
              :class="heightClass"
              @input="handleInput($event)"
              @keydown.enter="keyDownEnterInput"
            >
              <UiIcon
                name="search"
                :class="[isSearchInputFocused ? 'text-primary-100' : 'text-black-60']"
                class="peer/icon-prefix z-10"
              />
              <input
                :id="name"
                :value="shallowValue"
                :name="name"
                type="text"
                autocomplete="off"
                placeholder="Search"
                class="peer z-10 h-[20px] w-[inherit] flex-1 border-none bg-transparent outline-none placeholder:text-sm placeholder:font-normal placeholder:leading-5"
                @focus="isSearchInputFocused = true"
                @blur="isSearchInputFocused = false"
              />
              <transition name="fade" mode="out-in">
                <UiIcon
                  v-if="isSearchInputFocused && !isShallowValueEmpty"
                  name="big-close"
                  class="peer/icon z-10 cursor-pointer text-black-60 transition-all duration-200 peer-focus-visible:text-primary-100"
                  @click="shallowValue = ''"
                />
              </transition>
              <div
                class="absolute left-0 z-0 size-full rounded-xl border-[1.5px] border-solid border-black-20 outline-none transition-colors duration-200 hover:border-primary-50 active:border-primary-100 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50 peer-focus:border-primary-100 peer-active:border-primary-100 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05"
              />
            </label>
            <ol class="styled-scrollbar-near overflow-y-auto overflow-x-hidden" :class="{ 'max-h-[250px]': maxHeight }">
              <UiLoader v-if="loading" class="mt-2" />
              <div v-else-if="multiple">
                <UiInputCheckbox
                  v-if="withSelectAll"
                  v-model="allSelected"
                  class="w-full border-b border-solid border-black-10 p-3 hover:bg-black-03 active:bg-primary-05"
                  :class="[compact ? 'h-10' : 'h-12', { 'text-primary-100': allSelected }]"
                  name="select_all"
                  label="Select all"
                  :indeterminate="indeterminate"
                  @click.stop
                />
                <UiInputSelectMultipleItem
                  v-for="(item, index) in filteredItems"
                  :key="index"
                  :model-value="modelValue"
                  :compact
                  :avatar
                  :item="item"
                  @input="multipleSelect"
                />
              </div>
              <div v-else>
                <UiInputSelectSimpleItem
                  v-for="(item, index) in filteredItems"
                  :key="index"
                  :compact
                  :avatar
                  :model-value="modelValue"
                  :shallow-value="shallowValue"
                  :item="item"
                  @input="select"
                />
              </div>
              <li
                v-if="!filteredItems.length && !loading"
                class="flex select-none items-center p-3"
                :class="heightClass"
              >
                <div
                  v-if="addNew && !multiple"
                  class="text-subhead-1 flex flex-row items-center gap-2 text-primary-100"
                  @click="select(shallowValue as string)"
                >
                  <UiIcon name="big-add-circle"></UiIcon>
                  Add as a new value
                </div>
                <div v-else class="text-body-2 text-black-60">No options</div>
              </li>
            </ol>
          </div>
        </transition>
      </Teleport>
    </label>
    <div class="absolute -bottom-4 h-4 w-full" :class="[errorWrapperClass]">
      <transition name="fade" mode="out-in">
        <p v-if="error" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-error-100">
          {{ error }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import orderBy from 'lodash/orderBy'
import isArray from 'lodash/isArray'
import type { InputItem } from '@/types'

const HEADER_VALUE = 'heading'

const emits = defineEmits(['update:modelValue', 'updated:shallowValue', 'clickOutside'])

const slots = defineSlots()

type Props = {
  modelValue: any
  items: InputItem[]
  name: string
  label?: string
  placeholder?: string
  persistentPlaceholder?: boolean // show always placeholder on multiple mode
  disabled?: boolean
  showIcon?: boolean
  icon?: string
  multiple?: boolean // activate multiple mode with checkboxes
  error?: string
  width?: number // changes the width of the menu
  addNew?: boolean // adds an option to add the written text as a new value
  removable?: boolean // adds an X to deselect everything
  alignRight?: boolean // to force the menu to align to the right of the input
  maxHeight?: boolean // sets the menu max height as 250px
  compact?: boolean // changes the height of the input to 40px
  hideArrow?: boolean // hides the select arrow
  loading?: boolean // adds a loading. to be used in conjunction with async
  async?: boolean // to retrieve items async. use with loading
  errorWrapperClass?: string // in case we need the error message to be longer
  withSelectAll?: boolean // to add a "Select all" option
  ghost?: boolean // to remove background and paddings
  textClass?: string // to add a class to the text
  group?: boolean
  avatar?: boolean
  valueAsArray?: boolean
  menuClasses?: string
  readOnly?: boolean
  closeOnSelect?: boolean
  hideMultipleSearch?: boolean
  sanitizeInput?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  modelValue: [],
  label: '',
  placeholder: '',
  error: '',
  icon: 'search',
  width: 0,
  maxHeight: true,
  hideArrow: false,
  errorWrapperClass: '',
  textClass: 'text-body-2',
  removable: true,
  menuClasses: '',
  closeOnSelect: true,
  hideMultipleSearch: false,
})

const isInputFocused = ref(false)
const shallowValue = ref<string | string[]>('' || [''])
const userTyped = ref<boolean>(false)

const heightClass = computed(() => (props.compact ? 'h-10' : 'h-12'))

const simpleInputText = computed(
  () =>
    props.items.find((i) => {
      if (props.valueAsArray) {
        return shallowValue.value?.includes(i.value)
      }
      return JSON.stringify(i.value) === JSON.stringify(shallowValue.value)
    })?.text || shallowValue.value
)

const isShallowValueEmpty = computed(() => {
  return isArray(shallowValue.value) ? !shallowValue.value[0] : !shallowValue.value
})

const filteredItems = computed(() => {
  if (userTyped.value && typeof shallowValue.value === 'string' && shallowValue.value) {
    const valueFormatted = (shallowValue.value as string).trim().toLowerCase()

    return props.items.filter(
      ({ value, text }) => value !== HEADER_VALUE && text.trim().toLowerCase().includes(valueFormatted)
    )
  }

  return props.multiple
    ? orderBy(
        props.items,
        (item) => {
          return props.modelValue.includes(item.value)
        },
        ['desc']
      )
    : props.items
})

const multipleItemsEditableContent = computed(() => {
  if (!props.multiple) return
  let string = props.items
    .filter((i) => props.modelValue.includes(i.value))
    .map((i) => i.text)
    .toString()
    .replace(',', '; ')

  if (string) string = string.concat(' ')
  return string
})

const dynamicPlaceholder = computed(() =>
  multipleItemsEditableContent.value
    ? props.persistentPlaceholder
      ? props.placeholder
      : props.modelValue.length >= 3
      ? 'Options Selected'
      : ''
    : isInputFocused.value
    ? 'Choose an option'
    : props.placeholder
)

const itemsWithoutHeader = computed(() => filteredItems.value.filter((i) => i.value !== HEADER_VALUE))

const allSelected = computed({
  get() {
    return props.modelValue?.length === itemsWithoutHeader.value.length
  },
  set(value) {
    if (value) {
      emits(
        'update:modelValue',
        props.items.map((i) => i.value).filter((value) => value !== HEADER_VALUE)
      )
    } else {
      emits('update:modelValue', [])
    }
  },
})

const indeterminate = computed(() =>
  Boolean(props.modelValue.length && props.modelValue.length !== itemsWithoutHeader.value.length)
)

const showSingleClearIcon = computed(() => {
  return props.removable && isInputFocused.value && (!isShallowValueEmpty.value || slots.default)
})

const setShallowValue = (value: any) => {
  if (props.multiple) return

  shallowValue.value = JSON.parse(JSON.stringify(value))
}

watch(
  () => props.modelValue,
  (value) => {
    setShallowValue(value)
  },
  { immediate: true, deep: true }
)

const refs = {
  [props.name]: ref<HTMLInputElement>(),
  [`${props.name}_select`]: ref<HTMLElement>(),
}

const calculatePosition = () => {
  if (!isInputFocused.value) return
  const viewportOffset = refs[props.name].value?.getBoundingClientRect()
  if (viewportOffset) {
    // Here we get the element position to the viewport
    const top = viewportOffset.top
    const left = viewportOffset.left

    //   Then we set the position of the menu to those coordinates (if theres space)
    //   We do this because we use Teleport to transport to the body of the page
    //   Because otherwise, the menu would be clipped by tables, forms, etc
    //   Source: Trust me
    nextTick(() => {
      const select = refs[`${props.name}_select`].value

      if (select) {
        const spaceToBottom = window.innerHeight - top
        if (spaceToBottom < select.offsetHeight + Number(refs[props.name].value?.offsetHeight)) {
          select.style.top = `${top - select.offsetHeight}px`
        } else {
          select.style.top = `${top + Number(refs[props.name].value?.offsetHeight)}px`
        }
        const spaceToRight = window.innerWidth - left

        const width = Number(props.width || refs[props.name].value?.offsetWidth)

        if (spaceToRight < width || props.alignRight) {
          select.style.left = `${left - width + Number(refs[props.name].value?.offsetWidth)}px`
        } else {
          select.style.left = `${left}px`
        }

        select.style.width = `${width}px`
      }
    })
  }
}

const setInputFocused = (value: boolean) => {
  if (props.disabled || props.readOnly) return
  isInputFocused.value = value
  calculatePosition()
}

const handleInput = (event: Event) => {
  if (props.disabled || props.readOnly) return
  setInputFocused(true)
  if (props.sanitizeInput) {
    const decodedString = decodeURIComponent((event.target as HTMLInputElement).value)
    const cleanString = decodedString.replace(/[^a-zA-Z0-9./\s]/g, '')
    shallowValue.value = cleanString.replace(/\s+/g, ' ').trim()
  } else {
    shallowValue.value = (event.target as HTMLInputElement).value
  }
  userTyped.value = true

  emits('updated:shallowValue', shallowValue.value)
}
const input = ref<HTMLInputElement | null>(null)

const handleClear = () => {
  if (props.multiple) {
    emits('update:modelValue', [])
  } else {
    select('', false)
  }

  if (input.value) {
    input.value.focus()
  }
}

const eraseWord = () => {
  nextTick(() => {
    if (props.multiple) {
      if (typeof shallowValue.value !== 'string' || shallowValue.value === '') {
        const itemToRemove = props.modelValue[props.modelValue.length - 1]

        if (itemToRemove && (props.modelValue as string[]).find((v) => v === itemToRemove)) {
          multipleSelect((props.modelValue as string[])[props.modelValue.length - 1])
        }
      }
    }
  })
}

const keyDownEnterInput = () => {
  // If the focused element is the input himself, then the first item on the list in the one the user wants
  // Otherwise, the focused element is the item itself
  if (
    (document.activeElement as HTMLInputElement).nodeName === 'INPUT' &&
    filteredItems.value[0] &&
    !filteredItems.value[0].disabled &&
    (props.multiple ? shallowValue.value.length : shallowValue.value)
  ) {
    props.multiple ? multipleSelect(filteredItems.value[0].value) : select(filteredItems.value[0].value)
  } else if (props.addNew && shallowValue.value) {
    select(shallowValue.value as string)
  }
}

const select = (value: string, blur = props.closeOnSelect) => {
  userTyped.value = false

  if (blur) {
    isInputFocused.value = false
  }

  let valueFormatted: string | string[] = value

  const isValueExist = Boolean(value)

  if (props.valueAsArray) {
    if (isValueExist) {
      valueFormatted = [value]
    } else {
      valueFormatted = []
    }
  }

  setShallowValue(valueFormatted)

  emits('update:modelValue', valueFormatted)
}

const multipleSelect = (value: string) => {
  let newArray = []
  // Remove or add depending if the value is in the array
  if (props.modelValue.includes(value)) {
    newArray = (props.modelValue as string[]).filter((v: string) => v !== value)
  } else {
    newArray = Array.from(new Set([...props.modelValue, value]))
  }

  emits('update:modelValue', newArray)
}

const isSearchInputFocused = ref(false)

onMounted(() => {
  const select = refs[`${props.name}_select`]
  const input = refs[props.name]

  onClickOutside(
    select,
    () => {
      isInputFocused.value = false
      isSearchInputFocused.value = false

      userTyped.value = false

      if (props.multiple) {
        shallowValue.value = ''
      }

      setShallowValue(props.modelValue)

      emits('clickOutside')
    },
    {
      ignore: [input],
    }
  )

  window.addEventListener('scroll', calculatePosition, true)
})
onUnmounted(() => window.removeEventListener('scroll', calculatePosition))

defineExpose({
  setInputFocused,
})
</script>

<style scoped></style>
